// ** React && MUI
import { useEffect, useMemo, useState } from 'react';
import { Avatar, Badge, Box, TextField, Typography } from '@mui/material';

// ** Hooks
import { useSession } from '@/context/session';
import { useEthersProvider } from '@/hooks/useEthersAdapters';

// ** Utils & Types
import { getNetworkIcon, getTokenIcon } from '@/utils/uniswap/constants';
import { Token } from '@uniswap/sdk-core';
import { RenderTokenBalance } from './TokenBalance';
import { getCurrencyBalance } from '../libs/wallet';
import { useHandleNetwork } from '@/context/network';
interface SwapTokenInputProps {
  amount: string;
  token?: Token;
  handleAmountChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isTokenOut?: boolean;
  tokenOutImg?: string;
}
export const SwapTokenInput = ({
  amount,
  handleAmountChange,
  token,
  isTokenOut = false,
  tokenOutImg
}: SwapTokenInputProps) => {
  const [tokenBalance, setTokenBalance] = useState<string | null>(null);
  const provider = useEthersProvider();
  const {
    chainId
  } = useHandleNetwork();
  const {
    user
  } = useSession();
  const {
    address
  } = user;
  const insufficientBalance = Number(amount) > Number(tokenBalance);
  useEffect(() => {
    if (address && token) {
      fetchBalanceAmount(address, token);
    }
    return cleanState;
  }, [address, token]);
  const fetchBalanceAmount = async (userAddress: string, token: Token) => {
    if (!address || !provider) return;
    try {
      const amount = await getCurrencyBalance(provider, userAddress, token);
      setTokenBalance(amount);
    } catch (error) {
      console.log(`Error fetching user balance on ${token.symbol && token.symbol} token: ${error}`);
    }
  };
  const cleanState = () => {
    setTokenBalance(null);
  };
  const networkIconSrc = useMemo(() => {
    if (!chainId) return '';
    return getNetworkIcon(chainId);
  }, [chainId]);
  const tokenOutIconSrc = useMemo(() => {
    if (tokenOutImg) return tokenOutImg;
    if (token && token.symbol) return getTokenIcon(token.symbol);
    return '';
  }, [token, tokenOutImg]);
  const textFieldLabel = useMemo(() => {
    if (isTokenOut) return 'For';
    return insufficientBalance ? 'Insufficient balance' : 'Sell';
  }, [isTokenOut, insufficientBalance]);
  return <Box data-sentry-element="Box" data-sentry-component="SwapTokenInput" data-sentry-source-file="SwapTokenInput.tsx">
      <Box display="flex" alignItems="center" gap={2} mb={1} data-sentry-element="Box" data-sentry-source-file="SwapTokenInput.tsx">
        <TextField label={textFieldLabel} variant="outlined" size="small" name={isTokenOut ? 'amounOut' : 'amountIn'} value={amount} onChange={handleAmountChange} sx={{
        flex: 1
      }} error={!isTokenOut && insufficientBalance} data-sentry-element="TextField" data-sentry-source-file="SwapTokenInput.tsx" />
        <Box display={'flex'} alignItems={'center'} gap={1} data-sentry-element="Box" data-sentry-source-file="SwapTokenInput.tsx">
          <Typography variant="caption" color={'text.secondary'} fontWeight={'bold'} fontSize={14} data-sentry-element="Typography" data-sentry-source-file="SwapTokenInput.tsx">
            {token?.name ?? token?.symbol}
          </Typography>
          <Badge color={'info'} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }} overlap="circular" badgeContent={<Avatar src={networkIconSrc} sx={{
          width: 20,
          height: 20
        }} />} sx={{
          '.MuiBadge-badge': {
            padding: 0
          }
        }} data-sentry-element="Badge" data-sentry-source-file="SwapTokenInput.tsx">
            <Avatar src={tokenOutIconSrc} sx={{
            width: 32,
            height: 32
          }} data-sentry-element="Avatar" data-sentry-source-file="SwapTokenInput.tsx" />
          </Badge>
        </Box>
      </Box>

      {token?.symbol ? <RenderTokenBalance tokenSymbol={token.symbol} tokenBalance={tokenBalance} /> : null}
    </Box>;
};