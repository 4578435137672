// This file contains code to easily connect to and get information from a wallet on chain

import { Currency } from '@uniswap/sdk-core'
import { ethers, FallbackProvider, formatEther, JsonRpcProvider } from 'ethers'

import { ABIS, getTokenBySymbol } from '@/utils/uniswap/constants'
import { sendTransaction } from './providers'
import { toReadableAmount } from '@/utils/uniswap/utils'
import { erc20Abi } from 'viem'

export async function getCurrencyBalance(
  provider: JsonRpcProvider | FallbackProvider,
  address: string,
  currency: Currency
): Promise<string> {
  // Handle ETH directly
  if (currency.isNative) {
    return formatEther(await provider.getBalance(address))
  }

  // Get currency otherwise
  const ERC20Contract = new ethers.Contract(
    currency.address,
    erc20Abi,
    provider
  )
  const balance: number = await ERC20Contract.balanceOf(address)
  const decimals: number = await ERC20Contract.decimals()

  // Format with proper units (approximate)
  return toReadableAmount(balance, decimals)
}

// wraps ETH (rounding up to the nearest ETH for decimal places)
export async function wrapETH(
  eth: number,
  provider: JsonRpcProvider | FallbackProvider,
  userAddress: string
) {
  if (!provider) {
    throw new Error('No provider found')
  }

  const chainId = (await provider.getNetwork()).chainId

  const WETH_TOKEN = getTokenBySymbol(Number(chainId), 'WETH')
  if (!WETH_TOKEN) throw new Error('WETH token not found')

  const wethContract = new ethers.Contract(
    WETH_TOKEN.address,
    ABIS['WETH'],
    provider
  )

  const bigNumberFrom = Math.ceil(eth)

  const transaction = {
    data: wethContract.interface.encodeFunctionData('deposit'),
    value: BigInt(Math.ceil(eth)) * 10n ** 18n,
    from: userAddress,
    to: WETH_TOKEN.address,
  }

  await sendTransaction(transaction, provider)
}

// unwraps ETH (rounding up to the nearest ETH for decimal places)
export async function unwrapETH(
  eth: number,
  provider: JsonRpcProvider | FallbackProvider,
  userAddress: string
) {
  if (!provider) {
    throw new Error('No provider found')
  }

  const chainId = (await provider.getNetwork()).chainId

  const WETH_TOKEN = getTokenBySymbol(Number(chainId), 'WETH')
  if (!WETH_TOKEN) throw new Error('WETH token not found')

  const wethContract = new ethers.Contract(
    WETH_TOKEN.address,
    ABIS['WETH'],
    provider
  )

  const transaction = {
    data: wethContract.interface.encodeFunctionData('withdraw', [
      BigInt(Math.ceil(eth)) * 10n ** 18n,
    ]),
    from: userAddress,
    to: WETH_TOKEN.address,
  }

  await sendTransaction(transaction, provider)
}
