import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { CardContent, Box, Divider, Theme, SxProps, CardHeader, CardContentProps } from '@mui/material';
import { useSelector } from '@/store/Store';
import { AppState } from '@/store/Store';
type Props = {
  title?: string;
  subtitle?: string;
  action?: JSX.Element | any;
  footer?: JSX.Element;
  footerContent?: JSX.Element;
  children?: JSX.Element;
  sx?: SxProps<Theme> | undefined;
  contentProps?: CardContentProps;
};
const BaseModal = forwardRef<HTMLDivElement, Props>(({
  title,
  subtitle,
  children,
  action,
  footer,
  footerContent,
  sx: sxProps,
  contentProps
}, ref) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  const modalStyle: SxProps<Theme> = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh',
    minHeight: '10rem',
    minWidth: '20rem',
    width: {
      md: 600
    },
    overflow: 'auto',
    border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
    borderRadius: '1rem',
    bgcolor: theme.palette.background.paper,
    padding: 1,
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none'
  };
  return <Box ref={ref} sx={sxProps ? {
    ...modalStyle,
    ...sxProps
  } : modalStyle} tabIndex={-1} role="dialog" aria-labelledby={title ? 'modal-title' : undefined} aria-describedby="modal-description">
        {(title || subtitle || action) && <CardHeader id="modal-title" title={title} subheader={subtitle} action={action} sx={{
      width: '100%',
      justifyContent: 'space-between'
    }} />}

        {(title || subtitle) && <Divider sx={{
      width: '100%',
      my: 0.1
    }} />}

        <CardContent id="modal-description" sx={{
      paddingX: '1.5rem',
      paddingTop: '0'
    }} {...contentProps}>
          {children}
          {footerContent}
        </CardContent>

        {footer && <>
            <Divider sx={{
        width: '100%',
        my: 0.1
      }} />
            <Box p={3}>{footer}</Box>
          </>}
      </Box>;
});
BaseModal.displayName = 'BaseModal';
export default BaseModal;