import { Box, IconButton, TextField, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
interface SwapSettingsProps {
  handleSettingsClose: () => void;
  settings: {
    maxSlip: number;
    minsDeadline: number;
  };
  handleSettingsChange: (e: any) => void;
}
export const SwapSettings = ({
  handleSettingsClose,
  settings,
  handleSettingsChange
}: SwapSettingsProps) => {
  const theme = useTheme();
  return <>
      {/* Modal Header */}
      <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 2,
      borderBottom: `1px solid ${theme.palette.divider}`
    }} data-sentry-element="Box" data-sentry-source-file="SwapSettings.tsx">
        <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="SwapSettings.tsx">Settings</Typography>
        <IconButton onClick={handleSettingsClose} data-sentry-element="IconButton" data-sentry-source-file="SwapSettings.tsx">
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="SwapSettings.tsx" />
        </IconButton>
      </Box>

      {/* Modal Content */}
      <Box sx={{
      padding: 2
    }} data-sentry-element="Box" data-sentry-source-file="SwapSettings.tsx">
        <Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="SwapSettings.tsx">Max Slippage</Typography>
        <TextField name="maxSlip" type="number" fullWidth variant="outlined" size="small" sx={{
        mt: 1,
        mb: 2
      }} value={settings.maxSlip} onChange={handleSettingsChange} InputProps={{
        endAdornment: <Typography>%</Typography>
      }} inputProps={{
        min: 0,
        step: 0.1
      }} data-sentry-element="TextField" data-sentry-source-file="SwapSettings.tsx" />
        <Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="SwapSettings.tsx">Transaction Deadline</Typography>
        <TextField name="minsDeadline" type="number" fullWidth variant="outlined" size="small" sx={{
        mt: 1
      }} value={settings.minsDeadline} onChange={handleSettingsChange} InputProps={{
        endAdornment: <Typography>minutes</Typography>
      }} inputProps={{
        min: 0
      }} data-sentry-element="TextField" data-sentry-source-file="SwapSettings.tsx" />
      </Box>
    </>;
};