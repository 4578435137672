// ** Mui & React
import { styled, Container, Box, useTheme } from '@mui/material';
import { useSelector } from '../../store/Store';
import { AppState } from '../../store/Store';

// ** Components
import Header from './vertical/header/Header';
import Sidebar from './vertical/sidebar/Sidebar';
import Navigation from '../full/horizontal/navbar/Navigation';
import HorizontalHeader from '../full/horizontal/header/Header';
import MobileNavigation from './vertical/mobile';

// ** Hooks & Contexts
import { useAccount } from 'wagmi';
import { useModalsState } from '@/context/modals';

// ** Modals
import { ConfirmModal } from '@/components/modals/confirm';
import { ErrorModal } from '@/components/modals/error';
import { SignInProcessModal } from '@/components/modals/sign-in';
import { CommentModal } from '@/components/modals/comment';
import { NotConnectedModal } from '@/components/modals/not-connected';
import { CollectModal } from '@/components/modals/collect';
import { ConnectWalletModal } from '@/components/modals/connectWallet';
import { ShareStatsModal } from '@/components/modals/share-stats';
import { ZoraCollectModal } from '@/components/modals/zora-collect';
import { PodCollectModal } from '@/components/modals/pod-collect';
import { UniswapWidgetModal } from '@/components/modals/uniswap-widget';
const MainWrapper = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  width: '100%'
}));
const PageWrapper = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  paddingBottom: '60px',
  flexDirection: 'column',
  zIndex: 1,
  width: '100%',
  backgroundColor: 'transparent'
}));
const BottomNavWrapper = styled(Box)(() => ({
  position: 'sticky',
  bottom: -2,
  zIndex: 5000
}));
interface Props {
  children: React.ReactNode;
}
const FullLayout: React.FC<Props> = ({
  children
}) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const {
    isConnecting
  } = useAccount();
  const {
    signInProcess
  } = useModalsState();
  return <Box display="flex" flexDirection="column" minHeight="100vh" data-sentry-element="Box" data-sentry-component="FullLayout" data-sentry-source-file="FullLayout.tsx">
      <MainWrapper data-sentry-element="MainWrapper" data-sentry-source-file="FullLayout.tsx">
        {/* ------------------------------------------- */}
        {/* Sidebar */}
        {/* ------------------------------------------- */}
        {customizer.isHorizontal ? '' : <Sidebar />}
        {/* ------------------------------------------- */}
        {/* Main Wrapper */}
        {/* ------------------------------------------- */}
        <PageWrapper className="page-wrapper" sx={{
        ...(customizer.isCollapse && {
          [theme.breakpoints.up('lg')]: {
            ml: `${customizer.MiniSidebarWidth}px`
          }
        })
      }} data-sentry-element="PageWrapper" data-sentry-source-file="FullLayout.tsx">
          {/* ------------------------------------------- */}
          {/* Header */}
          {/* ------------------------------------------- */}
          {customizer.isHorizontal ? <HorizontalHeader /> : <Header />}
          {/* PageContent */}
          {customizer.isHorizontal ? <Navigation /> : ''}
          <Container sx={{
          maxWidth: '100%!important'
        }} data-sentry-element="Container" data-sentry-source-file="FullLayout.tsx">
            {/* ------------------------------------------- */}
            {/* PageContent */}
            {/* ------------------------------------------- */}

            <Box sx={{
            minHeight: 'calc(100vh - 170px)'
          }} data-sentry-element="Box" data-sentry-source-file="FullLayout.tsx">
              {/* <Outlet /> */}
              {children}
              {/* <Index /> */}
            </Box>

            {/* ------------------------------------------- */}
            {/* End Page */}
            {/* ------------------------------------------- */}
          </Container>
        </PageWrapper>
      </MainWrapper>

      <BottomNavWrapper id="mobile-navigation" sx={isConnecting ? {
      zIndex: 80
    } : undefined} data-sentry-element="BottomNavWrapper" data-sentry-source-file="FullLayout.tsx">
        <MobileNavigation data-sentry-element="MobileNavigation" data-sentry-source-file="FullLayout.tsx" />
      </BottomNavWrapper>

      {/* ------------------------------------------- */}
      {/* Modals */}
      {/* ------------------------------------------- */}
      <ConfirmModal data-sentry-element="ConfirmModal" data-sentry-source-file="FullLayout.tsx" />
      <ErrorModal data-sentry-element="ErrorModal" data-sentry-source-file="FullLayout.tsx" />
      <NotConnectedModal data-sentry-element="NotConnectedModal" data-sentry-source-file="FullLayout.tsx" />
      <CommentModal data-sentry-element="CommentModal" data-sentry-source-file="FullLayout.tsx" />
      <CollectModal data-sentry-element="CollectModal" data-sentry-source-file="FullLayout.tsx" />
      <ZoraCollectModal data-sentry-element="ZoraCollectModal" data-sentry-source-file="FullLayout.tsx" />
      <ConnectWalletModal data-sentry-element="ConnectWalletModal" data-sentry-source-file="FullLayout.tsx" />
      <ShareStatsModal data-sentry-element="ShareStatsModal" data-sentry-source-file="FullLayout.tsx" />
      <PodCollectModal data-sentry-element="PodCollectModal" data-sentry-source-file="FullLayout.tsx" />
      <UniswapWidgetModal data-sentry-element="UniswapWidgetModal" data-sentry-source-file="FullLayout.tsx" />
      {signInProcess.isOpen && <SignInProcessModal />}
    </Box>;
};
export default FullLayout;