import { Button, ButtonProps } from '@mui/material';
import { useSwitchChain } from 'wagmi';
import { DEFAULT_NETWORK } from '@/utils/constants/networks';
type Props = {
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  label?: string;
  networkToSwitch?: number;
};
export const SwitchNetwork = ({
  variant = 'text',
  color = 'primary',
  size = 'medium',
  label = 'Switch Network',
  networkToSwitch = DEFAULT_NETWORK
}: Props) => {
  const {
    switchChain
  } = useSwitchChain();
  const handleSwitchNetwork = () => {
    switchChain?.({
      chainId: networkToSwitch
    });
  };
  return <Button variant={variant} color={color} size={size} onClick={handleSwitchNetwork} data-sentry-element="Button" data-sentry-component="SwitchNetwork" data-sentry-source-file="SwitchNetwork.tsx">
      {label}
    </Button>;
};