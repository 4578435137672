// ** React & MUI
import { ChangeEvent, useMemo } from 'react';
import { Avatar, Box, Button, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import { IconArrowDown } from '@tabler/icons-react';

// ** Hooks
import { useHandleNetwork } from '@/context/network';
import { useSession } from '@/context/session';

// ** Components
import { SwapTokenInput } from './SwapTokenInput';

// ** Utils & Types
import { StatusEnum as Status } from '@/types/custom';
import { Token } from '@uniswap/sdk-core';
import { getTokenIcon, getUniswapTokensByNetwork } from '@/utils/uniswap/constants';
interface SwapInputProps {
  tokenIn?: Token;
  amountIn: string;
  handleAmountChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleTokenSwap: () => void;
  handleButtonClick: () => void;
  quoteStatus: Status;
  tradeCreationStatus: Status;
  tradeStatus: Status;
  amountOut: string;
  tokenOut?: Token;
  tokenOutImg?: string;
  handleSelectToken: (tokenSelected: Token) => void;
}
export const SwapInput = ({
  tokenIn,
  amountIn,
  handleAmountChange,
  handleButtonClick,
  quoteStatus,
  tradeCreationStatus,
  tradeStatus,
  tokenOut,
  amountOut,
  tokenOutImg,
  handleSelectToken
}: SwapInputProps) => {
  const theme = useTheme();
  const {
    notLoggedIn,
    connectedNotLogged
  } = useSession();
  const {
    chainId
  } = useHandleNetwork();
  const tokens = getUniswapTokensByNetwork(chainId);
  const buttonLabel = useMemo(() => {
    if (quoteStatus === Status.loading || tradeCreationStatus === Status.loading) {
      return <CircularProgress size={20} />;
    }
    return 'Review swap';
  }, [quoteStatus, tradeCreationStatus]);
  return <>
      <Box display={'flex'} justifyContent={'space-between'} gap={1} sx={{
      mb: 2
    }} data-sentry-element="Box" data-sentry-source-file="SwapInput.tsx">
        {tokens.map(token => <Button key={token.symbol} onClick={() => handleSelectToken(token)} color="info" sx={{
        p: 1,
        border: `1px solid ${tokenIn?.address === token.address ? theme.palette.primary.main : theme.palette.divider}`,
        minWidth: '2rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0.5,
        textTransform: 'none'
      }}>
            <Avatar src={token.symbol ? getTokenIcon(token.symbol) : ''} alt={token.name} sx={{
          width: 24,
          height: 24
        }} />
            <Typography variant="caption" color={'text.secondary'} fontWeight={'bold'}>
              {token.symbol}
            </Typography>
          </Button>)}
      </Box>

      {/* Swap Input */}
      <SwapTokenInput amount={amountIn} token={tokenIn} handleAmountChange={handleAmountChange} data-sentry-element="SwapTokenInput" data-sentry-source-file="SwapInput.tsx" />

      {/* Swap Button */}
      <IconButton color="primary" sx={{
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: '50%',
      marginBottom: 2
    }} data-sentry-element="IconButton" data-sentry-source-file="SwapInput.tsx">
        <IconArrowDown size={20} color={theme.palette.primary.main} data-sentry-element="IconArrowDown" data-sentry-source-file="SwapInput.tsx" />
      </IconButton>

      {/* Output */}
      <SwapTokenInput isTokenOut amount={amountOut} token={tokenOut} handleAmountChange={handleAmountChange} tokenOutImg={tokenOutImg} data-sentry-element="SwapTokenInput" data-sentry-source-file="SwapInput.tsx" />

      {/* Review Swap */}
      <Button variant="contained" disabled={notLoggedIn || connectedNotLogged || Number(amountIn) <= 0 || !tokenOut || Number(amountOut) <= 0 || quoteStatus === Status.loading || tradeCreationStatus === Status.loading || tradeStatus === Status.loading} fullWidth sx={{
      mt: 2
    }} onClick={handleButtonClick} data-sentry-element="Button" data-sentry-source-file="SwapInput.tsx">
        {buttonLabel}
      </Button>
    </>;
};