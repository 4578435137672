import { Box, Fade, Stack, Typography } from '@mui/material';
interface SwapStepProps {
  label: string;
  isActive?: boolean;
}
const SwapStep = ({
  label,
  isActive
}: SwapStepProps) => {
  return <Box display="flex" alignItems="center" gap={1} data-sentry-element="Box" data-sentry-component="SwapStep" data-sentry-source-file="SwapContent.tsx">
      <Box sx={{
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: isActive ? 'primary.main' : 'text.disabled',
      flexShrink: 0
    }} data-sentry-element="Box" data-sentry-source-file="SwapContent.tsx" />
      <Typography variant="body2" fontWeight={isActive ? 600 : 400} color={isActive ? 'text.primary' : 'text.secondary'} data-sentry-element="Typography" data-sentry-source-file="SwapContent.tsx">
        {label}
      </Typography>
    </Box>;
};
const SwapSteps = ({
  currentStep
}: {
  currentStep: 'approve' | 'sign' | 'confirm';
}) => {
  return <Box display="flex" flexDirection="column" gap={1} data-sentry-element="Box" data-sentry-component="SwapSteps" data-sentry-source-file="SwapContent.tsx">
      <SwapStep label="Approve in wallet" isActive={currentStep === 'approve'} data-sentry-element="SwapStep" data-sentry-source-file="SwapContent.tsx" />
      <SwapStep label="Sign message" isActive={currentStep === 'sign'} data-sentry-element="SwapStep" data-sentry-source-file="SwapContent.tsx" />
      <SwapStep label="Confirm swap" isActive={currentStep === 'confirm'} data-sentry-element="SwapStep" data-sentry-source-file="SwapContent.tsx" />
    </Box>;
};
interface SwapPreviewProps {
  networkCost: string;
  rate: string;
  maxSlippageValue?: string;
}
const SwapPreview = ({
  networkCost,
  rate,
  maxSlippageValue
}: SwapPreviewProps) => {
  return <Box display="flex" flexDirection="column" gap={1} data-sentry-element="Box" data-sentry-component="SwapPreview" data-sentry-source-file="SwapContent.tsx">
      <Stack direction="row" alignItems="center" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="SwapContent.tsx">
        <Box display="flex" alignItems="center" gap={1} data-sentry-element="Box" data-sentry-source-file="SwapContent.tsx">
          <Box sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'text.disabled',
          flexShrink: 0
        }} data-sentry-element="Box" data-sentry-source-file="SwapContent.tsx" />
          <Typography variant="body2" color={'text.secondary'} data-sentry-element="Typography" data-sentry-source-file="SwapContent.tsx">
            Network cost
          </Typography>
        </Box>
        <Typography variant="body2" color={'text.secondary'} data-sentry-element="Typography" data-sentry-source-file="SwapContent.tsx">
          {networkCost}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="SwapContent.tsx">
        <Box display="flex" alignItems="center" gap={1} data-sentry-element="Box" data-sentry-source-file="SwapContent.tsx">
          <Box sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'text.disabled',
          flexShrink: 0
        }} data-sentry-element="Box" data-sentry-source-file="SwapContent.tsx" />
          <Typography variant="body2" color={'text.secondary'} data-sentry-element="Typography" data-sentry-source-file="SwapContent.tsx">
            Rate
          </Typography>
        </Box>
        <Typography variant="body2" color={'text.secondary'} data-sentry-element="Typography" data-sentry-source-file="SwapContent.tsx">
          {rate}
        </Typography>
      </Stack>
      {maxSlippageValue ? <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Box sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'text.disabled',
          flexShrink: 0
        }} />
            <Typography variant="body2" color={'text.secondary'}>
              Max slippage
            </Typography>
          </Box>
          <Typography variant="body2" color={'text.secondary'}>
            {`${maxSlippageValue}%`}
          </Typography>
        </Stack> : null}
    </Box>;
};
interface SwapContentProps {
  isPreview: boolean;
  currentStep: 'approve' | 'sign' | 'confirm';
  networkCost: string;
  rate: string;
  maxSlippageValue?: string;
}
export const SwapContent = ({
  isPreview,
  currentStep,
  networkCost,
  rate,
  maxSlippageValue
}: SwapContentProps) => {
  return <Box sx={{
    position: 'relative',
    overflow: 'hidden',
    minHeight: isPreview ? '50px' : '65px',
    marginX: 1
  }} data-sentry-element="Box" data-sentry-component="SwapContent" data-sentry-source-file="SwapContent.tsx">
      {/* SwapReview Fade */}
      <Fade in={isPreview} timeout={300} data-sentry-element="Fade" data-sentry-source-file="SwapContent.tsx">
        <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: isPreview ? 'block' : 'none'
      }} data-sentry-element="Box" data-sentry-source-file="SwapContent.tsx">
          <SwapPreview networkCost={networkCost} rate={rate} maxSlippageValue={maxSlippageValue} data-sentry-element="SwapPreview" data-sentry-source-file="SwapContent.tsx" />
        </Box>
      </Fade>

      {/* SwapSteps Fade */}
      <Fade in={!isPreview} timeout={300} data-sentry-element="Fade" data-sentry-source-file="SwapContent.tsx">
        <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: !isPreview ? 'block' : 'none'
      }} data-sentry-element="Box" data-sentry-source-file="SwapContent.tsx">
          <SwapSteps currentStep={currentStep} data-sentry-element="SwapSteps" data-sentry-source-file="SwapContent.tsx" />
        </Box>
      </Fade>
    </Box>;
};