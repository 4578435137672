// ** MUI
import { Box, Modal, Typography } from '@mui/material';

// ** Hooks
import { useModalsActions, useModalsState } from '@/context/modals';
import { useHandleNetwork } from '@/context/network';

// ** Components
import { SwapWidget } from '../uniswap/widget';
import BaseModal from './base';
import { SwitchNetwork } from '../ctaButtons/network/SwitchNetwork';
export interface UniswapWidgetPayload {
  chainId: number;
  tokenAddress: string;
  tokenDecimals: number;
  tokenSymbol: string;
  tokenImg?: string;
}
export const UniswapWidgetModal = () => {
  const {
    chainId
  } = useHandleNetwork();
  const {
    close: closeModal
  } = useModalsActions();
  const {
    uniswapWidget
  } = useModalsState();
  const {
    isOpen,
    data
  } = uniswapWidget;
  const sameNetwork = chainId === data?.chainId;
  return <Modal open={isOpen} onClose={() => closeModal('uniswapWidget')} data-sentry-element="Modal" data-sentry-component="UniswapWidgetModal" data-sentry-source-file="uniswap-widget.tsx">
      <BaseModal sx={{
      minWidth: undefined,
      width: undefined
    }} contentProps={{
      sx: {
        padding: '0 !important'
      }
    }} data-sentry-element="BaseModal" data-sentry-source-file="uniswap-widget.tsx">
        {sameNetwork ? <SwapWidget token={data} /> : <Box position={'relative'}>
            <Box sx={{
          filter: 'blur(4px)',
          pointerEvents: 'none'
        }}>
              <SwapWidget token={data} />
            </Box>
            <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" alignItems="center" justifyContent="center">
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1} sx={{
            zIndex: 1,
            backgroundColor: theme => theme.palette.primary.light,
            padding: '1rem',
            borderRadius: '0.5rem'
          }}>
                <Typography variant="body1" fontWeight={600}>
                  Should change network
                </Typography>
                <SwitchNetwork variant={'outlined'} color="primary" size={undefined} label="Switch" networkToSwitch={data?.chainId} />
              </Box>
            </Box>
          </Box>}
      </BaseModal>
    </Modal>;
};