import { polygon, zora, base, arbitrum, optimism } from 'viem/chains'

/* CONFIG =========================================================================== */

export const POLYGON_ID = polygon.id
export const ZORA_ID = zora.id
export const BASE_ID = base.id

export const DEFAULT_NETWORK = POLYGON_ID

export const availableNetworks = [
  POLYGON_ID,
  ZORA_ID,
  BASE_ID,
  arbitrum.id,
  optimism.id,
]

/* NETWORKS & LIBREE_NETWORKS ======================================================= */

export const NETWORKS = [polygon, zora, base, arbitrum, optimism]

export const LIBREE_NETWORKS = NETWORKS.filter(
  (network) => availableNetworks.includes(network.id) && !network.testnet
)
