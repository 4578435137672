import { Box, Typography } from '@mui/material';
type Props = {
  tokenSymbol: string;
  tokenBalance: string | null;
};
export const RenderTokenBalance = ({
  tokenBalance,
  tokenSymbol
}: Props) => {
  if (tokenBalance === null) return <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} height={'1.25rem'} />;
  return <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} data-sentry-element="Box" data-sentry-component="RenderTokenBalance" data-sentry-source-file="TokenBalance.tsx">
      <Typography variant="caption" color="text.secondary" fontWeight={600} data-sentry-element="Typography" data-sentry-source-file="TokenBalance.tsx">
        {`${tokenBalance} ${tokenSymbol}`}
      </Typography>
    </Box>;
};