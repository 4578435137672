import { ethers, FallbackProvider, JsonRpcProvider } from 'ethers'
import { computePoolAddress, FeeAmount } from '@uniswap/v3-sdk'
import Quoter from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
import { getUniswapContractAddress } from '@/utils/uniswap/constants'
import { fromReadableAmount, toReadableAmount } from '@/utils/uniswap/utils'
import { Token } from '@uniswap/sdk-core'

// The amount returned is the number of output tokens, to be received for the quoted swap.
export async function quote(
  provider: JsonRpcProvider | FallbackProvider,
  tokenIn: Token,
  tokenOut: Token,
  poolFee: number,
  amountIn: number
): Promise<string> {
  if (!provider) {
    throw new Error('Provider not connected')
  }

  const chainId = (await provider.getNetwork()).chainId
  const quoterAddress = getUniswapContractAddress(chainId, 'QUOTER')

  const quoterContract = new ethers.Contract(
    quoterAddress,
    Quoter.abi,
    provider
  )

  const poolConstants = await getPoolConstants(
    provider,
    tokenIn,
    tokenOut,
    poolFee
  )

  const aux = fromReadableAmount(amountIn, tokenIn.decimals)

  const quotedAmountOut = await quoterContract.quoteExactInputSingle.staticCall(
    poolConstants.token0,
    poolConstants.token1,
    poolConstants.fee,
    aux.toString(),
    0
  )

  return toReadableAmount(quotedAmountOut, tokenOut.decimals)
}

async function getPoolConstants(
  provider: JsonRpcProvider | FallbackProvider,
  tokenA: Token,
  tokenB: Token,
  poolFee: FeeAmount
): Promise<{
  token0: string
  token1: string
  fee: number
}> {
  const chainId = (await provider.getNetwork()).chainId
  const factoryAddress = getUniswapContractAddress(chainId, 'POOL_FACTORY')

  const currentPoolAddress = computePoolAddress({
    factoryAddress,
    tokenA,
    tokenB,
    fee: poolFee,
    chainId: Number(chainId),
  })

  const poolContract = new ethers.Contract(
    currentPoolAddress,
    IUniswapV3PoolABI.abi,
    provider
  )

  const [token0, token1, fee] = await Promise.all([
    poolContract.token0(),
    poolContract.token1(),
    poolContract.fee(),
  ])

  return {
    token0,
    token1,
    fee,
  }
}
