import { arbitrum, base, optimism } from 'viem/chains'

export const POLYGON_RPCS = [
  'https://lb.nodies.app/v1/c4af832850924699b25128e185bde36e',
  'https://polygon-bor-rpc.publicnode.com',
  'https://rpc.ankr.com/polygon',
]

export const POLYGON_AMOY_RPCS = [
  'https://rpc-amoy.polygon.technology',
  'https://lb.nodies.app/v1/1c9670faa1fb4c59ac4b0185ec9f19b7',
  'https://polygon-amoy-bor-rpc.publicnode.com',
]

export const ZORA_RPCS = ['https://rpc.zora.energy']

export const BASE_RCPS = base.rpcUrls.default.http

export const OPTIMISM_RPCS = optimism.rpcUrls.default.http

export const ARBITRUM_RPCS = arbitrum.rpcUrls.default.http
