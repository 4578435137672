import {
  BrowserProvider,
  FallbackProvider,
  JsonRpcProvider,
  TransactionRequest,
} from 'ethers'

const browserExtensionProvider = createBrowserExtensionProvider()
let walletExtensionAddress: string | null = null

// Interfaces

export enum TransactionState {
  Failed = 'Failed',
  New = 'New',
  Rejected = 'Rejected',
  Sending = 'Sending',
  Sent = 'Sent',
}

// Wallet Functions

export async function sendTransaction(
  transaction: TransactionRequest,
  provider: JsonRpcProvider | FallbackProvider
): Promise<TransactionState> {
  return sendTransactionViaExtension(transaction, provider)
}

export async function connectBrowserExtensionWallet() {
  if (!window.ethereum) {
    return null
  }

  const { ethereum } = window
  const provider = new BrowserProvider(ethereum)
  const accounts = await provider.send('eth_requestAccounts', [])

  if (accounts.length !== 1) {
    return
  }

  walletExtensionAddress = accounts[0]
  return walletExtensionAddress
}

// Internal Functionality

function createBrowserExtensionProvider(): BrowserProvider | null {
  try {
    return new BrowserProvider(window?.ethereum, 'any')
  } catch (e) {
    console.log('No Wallet Extension Found')
    return null
  }
}

// Transacting with a wallet extension via a Web3 Provider
async function sendTransactionViaExtension(
  transaction: TransactionRequest,
  provider: JsonRpcProvider | FallbackProvider
): Promise<TransactionState> {
  try {
    const receipt = await browserExtensionProvider?.send(
      'eth_sendTransaction',
      [transaction]
    )

    const txStatus = await provider.waitForTransaction(receipt)

    const txConfirmations = await txStatus?.confirmations()

    if (txStatus && txConfirmations && txConfirmations > 0) {
      return TransactionState.Sent
    } else {
      return TransactionState.Failed
    }
  } catch (e) {
    console.log(e)
    return TransactionState.Rejected
  }
}
