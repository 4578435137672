import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
import { computePoolAddress } from '@uniswap/v3-sdk'
import { BigNumberish, ethers, FallbackProvider, JsonRpcProvider } from 'ethers'

import { getUniswapContractAddress } from '@/utils/uniswap/constants'
import { Token } from '@uniswap/sdk-core'

interface PoolInfo {
  token0: string
  token1: string
  fee: number
  tickSpacing: number
  sqrtPriceX96: BigNumberish
  liquidity: BigNumberish
  tick: number
}

export async function getPoolInfo(
  provider: JsonRpcProvider | FallbackProvider,
  tokenIn: Token,
  tokenOut: Token,
  poolFee: number
): Promise<PoolInfo> {
  if (!provider) {
    throw new Error('No provider')
  }

  const chainId = (await provider.getNetwork()).chainId
  const factoryAddress = getUniswapContractAddress(chainId, 'POOL_FACTORY')

  const currentPoolAddress = computePoolAddress({
    factoryAddress,
    tokenA: tokenIn,
    tokenB: tokenOut,
    fee: poolFee,
  })

  const poolContract = new ethers.Contract(
    currentPoolAddress,
    IUniswapV3PoolABI.abi,
    provider
  )

  const [token0, token1, fee, tickSpacing, liquidity, slot0] =
    await Promise.all([
      poolContract.token0(),
      poolContract.token1(),
      poolContract.fee(),
      poolContract.tickSpacing(),
      poolContract.liquidity(),
      poolContract.slot0(),
    ])

  return {
    token0,
    token1,
    fee,
    tickSpacing,
    liquidity,
    sqrtPriceX96: slot0[0],
    tick: slot0[1],
  }
}
