import { uniqueId } from 'lodash'

interface MenuitemsType {
  [x: string]: any
  id?: string
  navlabel?: boolean
  subheader?: string
  title?: string
  icon?: any
  href?: string
  children?: MenuitemsType[]
  chip?: string
  chipColor?: string
  variant?: string
  external?: boolean
  isProductionMode: boolean
  hide?: boolean
}
import {
  IconApps,
  IconAperture,
  IconUsers,
  IconUsersGroup,
  IconAward,
  IconList,
  IconPoint,
  IconTrophy,
  IconDashboard,
  IconHeartSearch,
  IconAlignBoxLeftBottom,
  IconBadges,
} from '@tabler/icons-react'
import { isProductionMode } from '@/utils'

const items: MenuitemsType[] = [
  {
    navlabel: true,
    subheader: 'Feed',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Feed',
    icon: IconList,
    href: '/',
    chipColor: 'secondary',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'Trending',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'People',
    icon: IconUsers,
    href: '/people',
    chipColor: 'secondary',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'Entertainment',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Collects & Mints',
    icon: IconAward,
    href: '/collects&Mints',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Top creators',
    icon: IconTrophy,
    href: '/topCreators',
    isProductionMode: false,
  },
  {
    navlabel: true,
    subheader: 'Dashboard',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Dashboard',
    icon: IconDashboard,
    href: '/dashboard',
    chipColor: 'secondary',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Topics',
    icon: IconHeartSearch,
    href: '/topics',
    chipColor: 'secondary',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'Communities',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Orb clubs',
    icon: IconUsersGroup,
    href: '/orbClubs',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'Apps',
    isProductionMode: false,
    hide: true,
  },
  {
    id: uniqueId(),
    title: 'Stats',
    icon: IconApps,
    href: '/apps',
    isProductionMode: false,
    hide: true,
  },
]

const Menuitems = isProductionMode
  ? items.filter((item) => item.isProductionMode)
  : items

export default Menuitems
