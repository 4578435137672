import { Token } from '@uniswap/sdk-core'
import { arbitrum, base, mainnet, optimism, polygon } from 'viem/chains'

// Default Chain ID
const DEFAULT_CHAIN_ID = polygon.id

// Address Constants
const CONTRACT_ADDRESSES = {
  [polygon.id]: {
    POOL_FACTORY: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    QUOTER: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
    V2_QUOTER: '0x61fFE014bA17989E743c5F6cB21bF9697530B21e',
    SWAP_ROUTER: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
    V3_SWAP_ROUTER: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
    UNIVERSAL_ROUTER: '0xec7BE89e9d109e7e3Fec59c222CF297125FEFda2',
  },
  [optimism.id]: {
    POOL_FACTORY: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    QUOTER: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
    V2_QUOTER: '0x61fFE014bA17989E743c5F6cB21bF9697530B21e',
    SWAP_ROUTER: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
    V3_SWAP_ROUTER: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
    UNIVERSAL_ROUTER: '0xCb1355ff08Ab38bBCE60111F1bb2B784bE25D7e8',
  },
  [arbitrum.id]: {
    POOL_FACTORY: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    QUOTER: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
    V2_QUOTER: '0x61fFE014bA17989E743c5F6cB21bF9697530B21e',
    SWAP_ROUTER: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
    V3_SWAP_ROUTER: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
    UNIVERSAL_ROUTER: '0x5E325eDA8064b456f4781070C0738d849c824258',
  },
  [base.id]: {
    POOL_FACTORY: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
    QUOTER: '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a', // QuoterV1 is not available on base.
    V2_QUOTER: '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a',
    SWAP_ROUTER: '0x2626664c2603336E57B271c5C0b26F421741e481', // SwapRouterV1 is not available on base
    V3_SWAP_ROUTER: '0x2626664c2603336E57B271c5C0b26F421741e481',
    UNIVERSAL_ROUTER: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
  },
}

export const getUniswapContractAddress = (
  chainId: bigint | number,
  name:
    | 'POOL_FACTORY'
    | 'QUOTER'
    | 'V2_QUOTER'
    | 'SWAP_ROUTER'
    | 'V3_SWAP_ROUTER'
    | 'UNIVERSAL_ROUTER'
) => {
  const chainIdNumber = Number(chainId)
  return CONTRACT_ADDRESSES[chainIdNumber as keyof typeof CONTRACT_ADDRESSES][
    name
  ]
}

// Token Registry
const TOKEN_REGISTRY: Record<
  string,
  {
    [chainId: number]: { address: string; decimals: number; name: string }
  }
> = {
  WETH: {
    [mainnet.id]: {
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      decimals: 18,
      name: 'Wrapped Ether',
    },
    [polygon.id]: {
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      decimals: 18,
      name: 'Wrapped Ether',
    },
    [optimism.id]: {
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
      name: 'Wrapped Ether',
    },
    [arbitrum.id]: {
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      decimals: 18,
      name: 'Wrapped Ether',
    },
    [base.id]: {
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
      name: 'Wrapped Ether',
    },
  },
  USDC: {
    [mainnet.id]: {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 6,
      name: 'USD Coin',
    },
    [polygon.id]: {
      address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
      decimals: 6,
      name: 'USD Coin',
    },
    [optimism.id]: {
      address: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
      decimals: 6,
      name: 'USD Coin',
    },
    [arbitrum.id]: {
      address: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
      decimals: 6,
      name: 'USD Coin',
    },
    [base.id]: {
      address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      decimals: 6,
      name: 'USD Coin',
    },
  },
  USDT: {
    [mainnet.id]: {
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      decimals: 6,
      name: 'Tether USD',
    },
    [polygon.id]: {
      address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      decimals: 6,
      name: 'Tether USD',
    },
    [optimism.id]: {
      address: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
      decimals: 6,
      name: 'Tether USD',
    },
    [arbitrum.id]: {
      address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      decimals: 6,
      name: 'Tether USD',
    },
  },
  DAI: {
    [mainnet.id]: {
      address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      decimals: 18,
      name: 'DAI',
    },
    [polygon.id]: {
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      decimals: 18,
      name: 'DAI',
    },
    [optimism.id]: {
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      decimals: 18,
      name: 'DAI',
    },
    [arbitrum.id]: {
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      decimals: 18,
      name: 'DAI',
    },
    [base.id]: {
      address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
      decimals: 18,
      name: 'DAI',
    },
  },
  WBTC: {
    [mainnet.id]: {
      address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      decimals: 18,
      name: 'Wrapped BTC',
    },
    [polygon.id]: {
      address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
      decimals: 18,
      name: 'Wrapped BTC',
    },
    [optimism.id]: {
      address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
      decimals: 18,
      name: 'Wrapped BTC',
    },
    [arbitrum.id]: {
      address: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      decimals: 18,
      name: 'Wrapped BTC',
    },
  },
}

// Utility Function to Create Token Instances
export const createToken = (
  symbol: string,
  chainId: number = DEFAULT_CHAIN_ID,
  tokenAddress?: string,
  tokenDecimals?: number
): Token | null => {
  const upperSymbol = symbol.toUpperCase()

  if (tokenAddress && tokenDecimals) {
    return new Token(chainId, tokenAddress, tokenDecimals, upperSymbol)
  }

  const tokenData = TOKEN_REGISTRY[upperSymbol]?.[chainId]
  if (!tokenData) return null
  const { address, decimals, name } = tokenData
  return new Token(chainId, address, decimals, upperSymbol, name)
}

const createNetworkTokens = (chainId: number) => {
  const tokens = DEFAULT_TOKENS.map((symbol) => createToken(symbol, chainId))
  return tokens.filter((token) => token !== null)
}

// Predefined Tokens
const DEFAULT_TOKENS = ['USDC', 'USDT', 'DAI', 'WETH', 'WBTC']

export const UNISWAP_TOKENS = {
  [polygon.id]: createNetworkTokens(polygon.id),
  [optimism.id]: createNetworkTokens(optimism.id),
  [arbitrum.id]: createNetworkTokens(arbitrum.id),
  [base.id]: createNetworkTokens(base.id),
}

export const getUniswapTokensByNetwork = (chainId?: number) => {
  return chainId && chainId in UNISWAP_TOKENS
    ? UNISWAP_TOKENS[chainId as keyof typeof UNISWAP_TOKENS]
    : []
}

// ABI Definitions
export const ABIS = {
  WETH: [
    // Wrap ETH
    'function deposit() payable',
    // Unwrap ETH
    'function withdraw(uint wad) public',
  ],
}

const TOKEN_ICONS: Record<string, string> = {
  WETH: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
  USDC: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png',
  USDT: 'https://cryptologos.cc/logos/tether-usdt-logo.png',
  DAI: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png',
  WBTC: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png',
}

export const getTokenBySymbol = (chainId: number, symbol: string) => {
  const chainTokens = getUniswapTokensByNetwork(chainId)
  return chainTokens.find(
    (token) => token.symbol?.toUpperCase() === symbol.toUpperCase()
  )
}

export const getTokenIcon = (symbol: string) =>
  TOKEN_ICONS[symbol.toUpperCase()]

const NETWORK_ICONS: Record<string, string> = {
  [polygon.id]: '/images/icons/polygon.png',
  [arbitrum.id]: '/images/icons/arbitrum.png',
  [optimism.id]: '/images/icons/optimism.png',
  [base.id]: '/images/icons/base.png',
}

export const getNetworkIcon = (id: number) => NETWORK_ICONS[id]

export const getChainByNetworkName = (networkName: string) => {
  const networks: Record<string, any> = {
    polygon,
    arbitrum,
    optimism,
    base,
  }

  return networks[networkName.toLowerCase()] || undefined
}
